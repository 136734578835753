@mixin contain-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@mixin cover-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin align-row-center {
  display: flex;
  align-items: center;
}
// 圆角大小
$bigBorderRadius: 8px;
$normalBorderRadius: 4px;

// 字体
$fontFamily: HarmonyOS Sans SC;

// 组件大小
$middleHeight: 32px;
$smallHeihgt: 24px;
$large: 40px;

:export {
  bigBorderRadius: $bigBorderRadius;
  normalBorderRadius: $normalBorderRadius;
  fontFamily: $fontFamily;
  middleHeight: $middleHeight;
  smallHeihgt: $smallHeihgt;
  large: $large;
}

.container {
  .notifyIcon {
    width: 16px;
    height: 16px;
    background-size: contain;
    cursor: pointer;
  }
}

.notifyContainer {
  padding: 12px;
  background-color: #28273a;
  min-height: 100px;
  width: 220px;
  max-height: 600px;
  overflow: auto;
  .titleContainer {
    text-align: right;
    cursor: pointer;
  }
  .notifyItem {
    background-color: #33333f;
    margin: 5px 0;
    padding: 5px 16px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    .viewContainer {
      width: 30px;
      flex-wrap: wrap;
      display: flex;
      align-content: space-around;
      .view {
        color: #5273e0;
        cursor: pointer;
      }
      .read {
        cursor: pointer;
      }
    }
  }
}
