@mixin contain-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@mixin cover-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin align-row-center {
  display: flex;
  align-items: center;
}
// 圆角大小
$bigBorderRadius: 8px;
$normalBorderRadius: 4px;

// 字体
$fontFamily: HarmonyOS Sans SC;

// 组件大小
$middleHeight: 32px;
$smallHeihgt: 24px;
$large: 40px;

:export {
  bigBorderRadius: $bigBorderRadius;
  normalBorderRadius: $normalBorderRadius;
  fontFamily: $fontFamily;
  middleHeight: $middleHeight;
  smallHeihgt: $smallHeihgt;
  large: $large;
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ant-btn-primary:disabled {
  border: 1px solid #444a63;
}

//修改自动填写输入框样式
.ant-input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px transparent inset !important;
  caret-color: #ffffff;
}

.ant-input:-internal-autofill-previewed {
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.ant-input:-internal-autofill-selected {
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.ant-form-item-label {
  height: 30px;
  line-height: 1.2;
}

.ant-table-thead > tr > th {
  padding: 9px 8px !important;
  // background-color: var(--White-table-header) !important;
  // white-space: nowrap;
}

.ant-table-tbody > tr > td {
  padding: 9px 8px !important;
  // white-space: nowrap;
}

.ant-pagination-options {
  background-color: var(--White-card);
  // .ant-select-selector {
  //   background-color: var(--White-card) !important;
  //   border: 1px solid var(--White-border) !important;
  //   color: var(--White-primary-text) !important;
  // }
}

.conform {
  .ant-modal-confirm-body {
    padding: 32px 32px 24px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 32px 24px;
  }
}

.ant-drawer .ant-drawer-header {
  border-bottom: 1px solid var(--White-dividers);
}

.ant-message-notice .ant-message-notice-content {
  background: var(--background-popover-bg);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  color: var(--White-title);
}

.ant-anchor-ink {
  // border-left: 1px solid var(--Primary-7);
  /* Primary/7 */
  background: var(--Primary-7) !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  opacity: 0.2;

  background: var(--White-border);
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.ant-tree-switcher {
  & > span {
    font-size: 12px !important;
    position: relative;
    top: 1px;
  }
}

.ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
  .ant-picker-time-panel-cell-inner {
    background-color: var(--White-hover) !important;
  }
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start {
  &::before {
    background-color: var(--White-hover) !important;
  }
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range {
  &::before {
    background-color: var(--White-hover) !important;
  }
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-selected {
  &::before {
    background-color: var(--White-hover) !important;
  }
}

// .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
//   background: var(--White-hover);
// }

.ant-tooltip {
  --antd-arrow-background-color: var(--background-popover-bg);
}
.ant-tooltip .ant-tooltip-inner {
  /* background/popover bg */

  background: var(--background-popover-bg);
  border-radius: 4px;
  /* White/title */

  color: var(--White-title);
}

.ant-table-row-selected > td {
  background-color: var(--White-hover) !important;
}

@font-face {
  font-family: Alimama_ShuHeiTi_Bold;
  src: url(../public/fonts/Alimama_ShuHeiTi_Bold.ttf);
}
@font-face {
  font-family: screen-time-font;
  src: url(../public/fonts/Furore-2.otf);
}
@font-face {
  font-family: YouSheBiaoTiHei;
  src: url(../public/fonts/YouSheBiaoTiHei-2.ttf);
}

// td.ant-table-cell-row-hover {
//   background: transparent !important;
// }
