@mixin contain-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@mixin cover-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin align-row-center {
  display: flex;
  align-items: center;
}
// 圆角大小
$bigBorderRadius: 8px;
$normalBorderRadius: 4px;

// 字体
$fontFamily: HarmonyOS Sans SC;

// 组件大小
$middleHeight: 32px;
$smallHeihgt: 24px;
$large: 40px;

:export {
  bigBorderRadius: $bigBorderRadius;
  normalBorderRadius: $normalBorderRadius;
  fontFamily: $fontFamily;
  middleHeight: $middleHeight;
  smallHeihgt: $smallHeihgt;
  large: $large;
}

.EmsNav {
  
  padding: 0 12px;
}

.icon {
  margin-right: 4px;
}
.title {
  // position: relative;
  // z-index: 1;
  color: var(--White-title);
}
.underline {

  .title {
    position: relative;
    z-index: 1;
    // color: var(--White-title);
  }

  .title:after {
    display: inline-block;
      content: ' ';
      width: calc(100% + 20px);
      height: 8px;
      background: #2b4acb;
      border-radius: 10px;
      position: absolute;
      top: 11px;
      left: -18px;
      z-index: -1;

  }
}